
/** @jsx jsx */
import React from 'react';
import { jsx, Container, Box, Image, Input, Textarea, Button } from 'theme-ui';
import Img from 'gatsby-image';
import { useStaticQuery, graphql } from 'gatsby';
import { nominalTypeHack } from 'prop-types';
import LogoDark from 'assets/logo-dark.svg';
import Logo from 'components/logo';

export default function Contact() {

        // Graphql image query
        const dataThumb = useStaticQuery(graphql`
        query {
            placeholderImage: file(relativePath: { eq: "wine-corks.jpeg" }) {
            childImageSharp {
                fluid(maxWidth: 1000) {
                ...GatsbyImageSharpFluid
                }
            }
            }
        }
        `);

    return (
        
        <>

        <header sx={styles.myHeader} id="myHeader">
            <Logo src={ LogoDark } />
        </header>

        <br /><br />
        <h1 align="center">Contact Us</h1>
        <Container sx={styles.containerBox}>
            <Box sx={styles.thumbnail}>
            <Img fluid={dataThumb.placeholderImage.childImageSharp.fluid} />
            </Box>
            <Box sx={styles.contentBox}>
                <form action="https://getform.io/f/03365f23-ab76-47b4-a6d1-8ee29280055f" method="POST" content-type="www-form-urlencoded">
                <div sx={styles.col2}>
                    <div sx={styles.formGroup}>
                        <label sx={styles.label}>Name</label>
                        <Input id="name" type="text" name="name" sx={styles.textBoxes} />
                    </div>
                    <div sx={styles.formGroup}>
                        <label sx={styles.label}>Email</label>
                        <Input id="email" type="email" name="email" sx={styles.textBoxes} />
                    </div>
                </div>
                <div sx={styles.col2}>
                    <div sx={styles.formGroup}>
                        <label sx={styles.label}>Message</label>
                        <Textarea name="message" sx={styles.textBoxes} />
                    </div>
                    <div sx={styles.formGroup}>
                        <input type="hidden" name="subscribe" value="no"  />
                        <input type="checkbox" name="subscribe" value="yes" />
                        Subscribe to our newsletter
                    </div>
                </div>
                
                

                <Button type="submit">Submit</Button>
                </form>
            </Box>
        </Container>
  



        </>

    );
}

const styles = {
    bgOverlay: {
      position: 'absolute',
      top: 0,
      right: 0,
      height: '100%',
      zIndex: -1,
      width: [
        '100%',
        null,
        null,
        'calc(50% + 400px)',
        'calc(50% + 480px)',
        'calc(50% + 570px)',
        null,
        'calc(50% + 625px)',
      ],
    //   backgroundImage: `url(${PaymentPattern})`,
      backgroundRepeat: `no-repeat`,
      backgroundPosition: 'top left',
      backgroundSize: 'cover',
    },
    containerBox: {
      display: 'flex',
      alignItems: 'flex-start',
      justifyContent: 'space-between',
      flexWrap: ['wrap', null, null, 'nowrap'],
    },
    thumbnail: {
      mb: -2,
      ml: [-4, '-40px', null, null, 0],
      order: [2, null, null, 0],
      pr: [6, null, 7, 0],
      width: [
        'calc(100% + 20px)',
        'calc(100% + 40px)',
        null,
        370,
        460,
        570,
        null,
        680,
      ],
    },
    contentBox: {
      width: ['100%', 420, 480, 380, 500, 570],
      mx: 'auto',
      flexShrink: 0,
      textAlign: ['center', null, null, 'left'],
      pt: [0, null, null, 4, '50px', null, 4, '80px'],
      pl: [0, null, null, 40, '90px'],
      pb: [7, null, null, 9],
      pr: [0, null, null, null, null, 6],
    },
    form: {
        padding: '40px',
        width: '100%',
        col2: {
            display: 'flex',
            width: '100%',
            flex: '0 0 100%',
        },
        formGroup: {
            display: 'flex',
            justifyContent: 'flex-start',
            flexDirection: 'column',
            flex: '0 1 100%',
            paddingRight: '40px',
        },
        label: {
            fontSize: 0.9,
            color: 'rgba(255,255,255,0.6)',
            marginBottom: '2rem',
        },
        // 'input[type="text"]': {
        //     background: 'transparent',
        //     border: 'none',
        //     color: 'red',
        // }

    
    },
    textBoxes: {
        background: 'transparent',
        border: 'none',
        cursor: 'pointer',
        padding: '10px',
        fontSize: '1.1rem',
        fontWeight: '300',
        color: 'black',
        borderBottom: '2px solid rgba(0,0,0,0.4)',
        transition: 'all 0.3s ease-in-out',
        marginBottom: '1rem',
        '&:focus': {
            border: 'none',
            outline: 'none',
            borderBottom: '2px solid #05f7ff'
        }
    },
    submitBtn: {
        width: '200px',
        height: '60px',
        cursor: 'pointer',
        background: 'blue',
        color: 'white',
        textTransform: 'uppercase',
        borderRadius: '10px',
    },
    myHeader: {
        color: 'black',
        backgroundColor: 'black',
        background: 'rgb(123,134,232)',
        fontWeight: 'normal',
        py: 4,
        width: '100%',
        position: 'absolute',
        top: 0,
        left: 0,
      },
    
  };
  